<template>
  <BootstrapCard headline="Anrufe">
    <ul class="list-group list-group-flush">
      <template v-for="call in eventLogs" :key="call.call_id">
        <li class="list-group-item">
          <div class="">
            <template v-if="call.direction === 'in'">
              <i class="fa-solid fa-phone-arrow-down-left"></i>
              {{ formatDate(call.events[0].created_at, 'datetime') }} | {{ call.counter_number }}
              <i class="fa-solid fa-arrow-right"></i>
              <template v-for="user_id in userIds(call)" :key="user_id">
                <UserAvatar :id="user_id" />
              </template>
            </template>
            <template v-else-if="call.direction === 'out'">
              <i class="fa-solid fa-phone-arrow-up-right"></i>
              {{ formatDate(call.events[0].created_at, 'datetime') }}
              <template v-for="user_id in userIds(call)" :key="user_id">
                <UserAvatar :id="user_id" class="me-1"/>
              </template>
              <i class="fa-solid fa-arrow-right"></i>
              {{ call.counter_number }}
            </template>
            <div class="opacity-50">
              <template v-if="call.duration">{{ call.duration }} Sekunden | </template>
              {{ call.events.map(e => e.event_payload.event).join(', ') }}
            </div>
          </div>
          <div class="list-group">
            <template v-for="(client, index) in call.clients" :key="index">
              <ClientButton :client="client" :search-string="call.counter_number"/>
            </template>
            <template v-for="(contact, index) in call.contacts" :key="index">
              <ContactButton :contact="contact" :search-string="call.counter_number"/>
            </template>
          </div>
          <CollapsedContent :id="`events-${call.call_id}`"
                            :label="`${call.events.length} events`" v-if="true">
            <ol>
              <template v-for="event in call.events" :key="event.id">
                <li>{{ JSON.stringify(event.event_payload) }}</li>
              </template>
            </ol>
          </CollapsedContent>
        </li>
      </template>
      <template v-if="eventLogs.length === 0">
        Keine aktuellen Anrufe
      </template>
    </ul>
    <!--          <td>{{ eventItem.event_payload?.call_id }}</td>-->
    <!--          <td>{{ eventItem.created_at }}</td>-->
    <!--          <td>{{ eventItem.event_name }}</td>-->
    <!--          <td>{{ eventItem.event_payload?.event }}</td>-->
    <!--          <td>{{ eventItem.event_payload?.direction === 'in' ? eventItem.event_payload?.from : eventItem.event_payload?.to }}</td>-->
    <!--          <td>{{ eventItem.event_payload?.direction }}</td>-->
    <!--          <td>{{ eventItem.event_payload }}</td>-->
  </BootstrapCard>
</template>
<script>
import BootstrapCard from "@/components/BootstrapCard.vue"
import {useEventLogStore} from "@/stores/event_logs";
import CollapsedContent from "@/components/CollapsedContent.vue";
import ContactButton from "@/pages/contacts/components/ContactButton.vue";
import ClientButton from "@/pages/clients/components/CiientButton.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: 'PlacetelCalls',
  components: {UserAvatar, ClientButton, ContactButton, CollapsedContent, BootstrapCard},
  props: {},
  setup() {
    return {
      eventLogStore: useEventLogStore(),
    }
  },
  channels: {
    DefaultChannel: {
      received(data) {
        if (data.model === 'EventLog' && data.service === 'placetel') {
          this.loadCalls()
        }
      },
    },
  },
  data() {
    return {
      eventLogs: [],
    }
  },
  created() {
    this.loadCalls()
  },
  computed: {},
  methods: {
    loadCalls() {
      this.eventLogStore.index('placetel').then(({data}) => {
        this.eventLogs = data //.filter(e => e.direction == 'in')
      })
    },
    userIds(call) {
      const userIds = call.events.map(e => e.user_id).filter(id => id)
      return [...new Set(userIds)]
    }
  },
}
</script>